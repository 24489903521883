import OnDemandThankYouPage from "components/thank-you/on-demand-video/OnDemandThankYouPage";
import React from "react";

export default function ThankYouPageRoot() {
  return (
    <OnDemandThankYouPage
      videoId="pcq9mhkc9g"
      title="Ensure Compliance with House Bill 322: How Rhombus Can Strengthen TN Schools’ Security"
      description="Discover a streamlined solution for a safe and secure learning environment, eliminating the complexities and high costs of traditional security systems."
    />
  );
}
